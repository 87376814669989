<template>
  <single-question-form
    :course-id="courseId"
    :question="question"
    :is-valid.sync="isValid"
    :submitting="creating"
    @input="v => $set(question, v.name, v.value)"
    @submit="save"
    @cancel="$emit('cancel')"
  ></single-question-form>
</template>

<script>
import SingleQuestionForm from "#ecf/question-bank/components/SingleQuestion/Form";
export default {
  name: "SingleQuestionCreate",
  components: { SingleQuestionForm },
  props: {
    courseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      question: {
        questionDetails: {
          text: undefined,
          questionType: "mcq",
          difficulty: undefined,
          topics: [],
          image: { imageURL: undefined, file: undefined }
        },
        answers: [
          {
            text: undefined,
            is_correct: false,
            image: { imageURL: undefined, file: undefined },
            explanation: undefined
          }
        ],
        answerPreference: {
          word_limit: 100
        }
      },
      isValid: false,
      creating: false
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  computed: {},
  created() {},
  methods: {
    async save() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      try {
        this.creating = true;
        for (let item of (this.question.answers || [])) {
          if (
            "explanation" in item &&
            item.explanation !== undefined &&
            item.explanation.length > 1000
          ) {
            this.$root.$emit("alert", [
              undefined,
              "explanations must be limited to 1000 characters"
            ]);
            return;
          }
        }
        if (this.question.questionDetails.questionType === "desc") {
          this.$delete(this.question, "answers");
        } else {
          this.$delete(this.question, "answerPreference");
        }
        
        await this.$store.dispatch("questionBank/create", {
          question: {
            ...this.question
          },
          courseId: this.courseId,
          ownerId: this.$courseOwnerId()
        });
        this.$emit("created");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.creating = false;
      }
    },
  }
};
</script>

<style></style>
