<template>
  <v-container>
    <page-title
        :page-title="
        !$language ? 'Create Single Question' : 'সিঙ্গেল প্রশ্ন তৈরি করুন'
      "
        class="mt-5"
    >
      <template #left>
        <v-btn
            class="mr-2"
            color="black"
            icon
            large
            @click="
            () => {
              $router.push({
                name: 'question-list',
              });
            }
          "
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
    </page-title>

    <div v-if="!smallScreen">
      <single-question-create
          v-if="!$route.query.duplicate_from"
          :course-id="courseId"
          @created="goBack"
          @cancel="goBack"
      ></single-question-create>
      <single-question-duplicate
          v-else
          :course-id="courseId"
          :question-id="$route.query.duplicate_from"
          @created="goBack"
          @cancel="goBack"
      >
      </single-question-duplicate>
    </div>
    <div v-else style="height: 60vh; color: grey;" class="center-content">
      Switch to a bigger screen to use this feature.
    </div>

    <!--    <lazy-load :item="!loadingQuestion">-->
    <!--      <single-question-create-form-->
    <!--        :question="sourceQuestion"-->
    <!--        @saved="goBack"-->
    <!--        @cancel="goBack"-->
    <!--      ></single-question-create-form>-->
    <!--    </lazy-load>-->
  </v-container>
</template>

<script>
import SingleQuestionCreate from "#ecf/question-bank/components/SingleQuestion/Create";
import SingleQuestionDuplicate from "#ecf/question-bank/components/SingleQuestion/Duplicate";

export default {
  name: "SingleQuestionCreatePage",
  components: {
    SingleQuestionDuplicate,
    SingleQuestionCreate,
  },
  props: {
    courseId: {
      type: String,
      required: true,
    },
  },
  inject: ["$courseOwnerId", "$courseId"],
  data: () => ({
    sourceQuestion: {questionDetails: {}},
    loadingQuestion: false,
    creating: false,
  }),
  computed: {
    smallScreen() {
      return this.$vuetify.breakpoint.width < 800;
    }
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      return;
    }
    if (this.$route.query.duplicate_from) {
      try {
        this.loadingQuestion = true;
        this.sourceQuestion = await this.$store.dispatch("questionBank/get", {
          questionId: this.$route.query.duplicate_from,
          owner_id: this.$courseOwnerId(),
          course_id: this.$courseId(),
        });
      } catch (e) {
        this.$notify(e, {type: "error"});
      } finally {
        this.loadingQuestion = false;
      }
    }
  },
  methods: {
    async goBack() {
      await this.$router.replace({name: "question-list"});
    },
  },
};
</script>
