<template>
  <lazy-load
    :item="
      !loadingQuestion[questionId] && question.questionDetails.questionType
    "
  >
    <single-question-form
      :course-id="courseId"
      :question="question"
      :is-valid.sync="isValid"
      :submitting="savingQuestion"
      @input="(v) => $set(question, v.name, v.value)"
      @updateqt="onTypeUpdate"
      @submit="save"
      @cancel="$emit('cancel')"
    ></single-question-form>
  </lazy-load>
</template>

<script>
import SingleQuestionForm from "#ecf/question-bank/components/SingleQuestion/Form";
import { QuestionCreator, QuestionProvider, EquationFinder } from "#ecf/question-bank/mixins";

const transformAnswer = (a) => {
  const newAns = { ...a };
  delete newAns.answer_id;
  return newAns;
};

export default {
  name: "SingleQuestionDuplicate",
  components: { SingleQuestionForm },
  mixins: [QuestionCreator, QuestionProvider, EquationFinder],
  props: {
    courseId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      question: {
        questionDetails: {},
        answers: [],
      },
      isValid: true,
      savingQuestion: false,
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  computed: {
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    }
  },
  async created() {
    const question = await this.fetchQuestion(this.questionId);
    question.questionDetails.text = await this.findEquationsFromQuestionText(
      question.questionDetails.text,
      question.question_id
    );
    this.$logger.log(question);
    const newQuestion = { ...question.questionDetails };
    delete newQuestion.question_id;
    this.$set(this.question, "questionDetails", newQuestion);
    this.$set(this.question, "answerPreference", question.answerPreference);
    this.$set(
      this.question,
      "answers",
      (question.answers || []).map(transformAnswer)
    );
  },
  methods: {
    onTypeUpdate(type) {
      let answers, preference;
      if (type == "fib") {
        answers = [
          {
            text: undefined,
            image: { imageURL: undefined, file: undefined },
            explanation: undefined,
          },
        ];
      } else if (type == "mcq") {
        answers = [
          {
            text: undefined,
            image: { imageURL: undefined, file: undefined },
            explanation: undefined,
          },
        ];
      } else {
        preference = { word_limit: 100 };
      }
      this.$set(this.question, "answers", answers);
      this.$set(this.question, "answerPreference", preference);
    },
    async save() {
      try {
        this.savingQuestion = true;
        if (this.question.questionDetails.questionType === "desc") {
          this.$delete(this.question, "answers");
        } else {
          this.$delete(this.question, "answerPreference");
        }
        await this.$store.dispatch("questionBank/duplicate", {
          question: {
            ...this.question,
            courseId: this.$courseId(),
            ownerId: this.$courseOwnerId(),
          },
        });
        this.$emit("created");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.savingQuestion = false;
      }
    },
  },
};
</script>

<style></style>
