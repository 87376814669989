var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("single-question-form", {
    attrs: {
      "course-id": _vm.courseId,
      question: _vm.question,
      "is-valid": _vm.isValid,
      submitting: _vm.creating,
    },
    on: {
      "update:isValid": function ($event) {
        _vm.isValid = $event
      },
      "update:is-valid": function ($event) {
        _vm.isValid = $event
      },
      input: (v) => _vm.$set(_vm.question, v.name, v.value),
      submit: _vm.save,
      cancel: function ($event) {
        return _vm.$emit("cancel")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }