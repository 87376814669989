var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lazy-load",
    {
      attrs: {
        item:
          !_vm.loadingQuestion[_vm.questionId] &&
          _vm.question.questionDetails.questionType,
      },
    },
    [
      _c("single-question-form", {
        attrs: {
          "course-id": _vm.courseId,
          question: _vm.question,
          "is-valid": _vm.isValid,
          submitting: _vm.savingQuestion,
        },
        on: {
          "update:isValid": function ($event) {
            _vm.isValid = $event
          },
          "update:is-valid": function ($event) {
            _vm.isValid = $event
          },
          input: (v) => _vm.$set(_vm.question, v.name, v.value),
          updateqt: _vm.onTypeUpdate,
          submit: _vm.save,
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }